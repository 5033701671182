import { useNavigate } from "react-router-dom";
import { Sticky } from "../components/Sticky/Sticky";
import { useAppContext } from "../context/AppContext";
import classNames from "classnames";
import "./Home.scss";
import { State } from "../components/LineUp/LineUp";
import { AVAILABLE_BRAINS, PLAYER_KEYS } from "../App";
import { useEffect, useState } from "react";

type Props = {};

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}
function Home() {
  return null;
}

export default Home;
