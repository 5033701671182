import { useReducer } from "react";

export type CommentaryItem = {
  type: "tackleA" | "scoreA" | "passA" | "tackleB" | "scoreB" | "passB";
  comment: string;
  pluralComment?: string;
};
const initialScoreboardState = {
  totalPassesTeamA: 0,
  totalPassesTeamB: 0,
  totalTacklesTeamA: 0,
  totalTacklesTeamB: 0,
  scoreA: 0,
  scoreB: 0,
  commentary: [] as CommentaryItem[],
};

type ScoreboardState = typeof initialScoreboardState;

export enum ScoreboardActionType {
  passTeamA,
  passTeamB,
  tackleTeamA,
  tackleTeamB,
  scoreA,
  scoreB,
}

export type ScoreboardAction = {
  type: ScoreboardActionType;
};

function scoreboardReducer(
  state: ScoreboardState,
  action: ScoreboardAction
): ScoreboardState {
  switch (action.type) {
    case ScoreboardActionType.passTeamA:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "passA",
            comment: `A great pass by Team A.`,
            pluralComment: "Great passing by Team A",
          },
        ],
        totalPassesTeamA: state.totalPassesTeamA + 1,
      };
    case ScoreboardActionType.passTeamB:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "passB",
            comment: `What a pass by Team B!`,
            pluralComment: "Great passing by Team B",
          },
        ],
        totalPassesTeamB: state.totalPassesTeamB + 1,
      };
    case ScoreboardActionType.tackleTeamA:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "tackleA",
            comment: `Close call, but a great interception by Team A!`,
            pluralComment: "Some great tackles by Team A",
          },
        ],
        totalTacklesTeamA: state.totalTacklesTeamA + 1,
      };
    case ScoreboardActionType.tackleTeamB:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "tackleB",
            comment: `What a tackle by Team B!`,
            pluralComment: "Some great tackles by Team B",
          },
        ],
        totalTacklesTeamB: state.totalTacklesTeamB + 1,
      };
    case ScoreboardActionType.scoreA:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "scoreA",
            comment: `Wonderful goal by Team A! The score is now ${
              state.scoreA + 1
            } versus ${state.scoreB}`,
          },
        ],
        scoreA: state.scoreA + 1,
      };
    case ScoreboardActionType.scoreB:
      return {
        ...state,
        commentary: [
          ...state.commentary,
          {
            type: "scoreA",
            comment: `Wonderful goal by Team B! The score is now ${
              state.scoreA
            } versus ${state.scoreB + 1}`,
          },
        ],
        scoreB: state.scoreB + 1,
      };
    default:
      throw new Error();
  }
}

export const useScoreboardReducer = () => {
  return useReducer(scoreboardReducer, initialScoreboardState);
};
