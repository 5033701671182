/* eslint-disable react/no-unknown-property */
import { useCallback, useEffect, useRef } from "react";
import { Vector3 } from "three";

import { Html } from "@react-three/drei";
import { RecordedEvent, parseEvent } from "./RecordedEvent";
import { CommentaryItem, useScoreboardReducer } from "./scoreboardReducer";
import { GameState, RecordedFrames } from "./ReplayModel";

type ModelProps = {
  replayData: RecordedFrames;
};
const initialState: GameState = {
  currentFrame: 0,
  timeScale: 1,
  players: [
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
  ],
  pastEvents: {
    players: [
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
    ],
  },
  ball: { position: new Vector3(), isSuperShot: false },
  score: { teamA: 0, teamB: 0, teamScored: null },
};

export const MatchDescription = ({ replayData }: ModelProps) => {
  const gameState = useRef<GameState>(initialState);
  const [{ commentary }, scoreboardDispatch] = useScoreboardReducer();
  // console.log("🚀 ~ commentary", commentary);

  // // we just want a global mixer to scale the time with

  const processFrameEvents = useCallback(
    (recordedFrameEvents: RecordedEvent[]) => {
      recordedFrameEvents.forEach((e) => {
        const frameEvent = parseEvent(e);
        // console.log("🚀 ~ frameEvent", frameEvent);
        frameEvent.handler(gameState.current, scoreboardDispatch);
      });
    },
    [scoreboardDispatch, gameState]
  );
  useEffect(() => {
    replayData.forEach((d) => {
      processFrameEvents(d.events);
    });
  }, [processFrameEvents, replayData]);

  // const reworded = filter(commentary);

  // find duplicate successive events, eg.g loads of passing
  const sequences: { start: number; end: number }[] = commentary.reduce(
    (reducedArray, data, index) => {
      if (index === 0) {
        reducedArray.push({ start: 0, end: 0 });
      }
      const prev = index > 0 ? commentary[index - 1] : data;
      if (prev.type !== data.type) {
        //grab last item
        reducedArray[reducedArray.length - 1].end = index;
        reducedArray.push({ start: index, end: index + 1 });
      }
      return reducedArray;
    },
    [] as { start: number; end: number }[]
  );

  const mapped = sequences.reduce((reducedArray, sequence, index) => {
    const { start, end } = sequence;
    const minimumDoubleUps = 2;
    const amountOfDoubleUps = end - start;
    const shouldBeMerged = amountOfDoubleUps >= minimumDoubleUps;
    if (shouldBeMerged) {
      const newMessage = { ...commentary[start] };
      newMessage.comment = newMessage.pluralComment || newMessage.comment;
      reducedArray.push(newMessage);
    } else {
      const fullRangeOfMessages = commentary.slice(start, end);
      reducedArray.push(...fullRangeOfMessages);
    }
    return reducedArray;
  }, [] as CommentaryItem[]);

  return (
    <section>
      <h1>Match for screen readers</h1>
      {mapped.map((c, index) => {
        return (
          <p aria-live={"polite"} key={index}>
            {c.comment}
          </p>
        );
      })}
      <p>
        The final score is {gameState.current.score.teamA} -{" "}
        {gameState.current.score.teamB}
      </p>
    </section>
  );
};
