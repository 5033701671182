import {
  Route,
  BrowserRouter,
  Routes,
  useLocation,
  createBrowserRouter,
  useOutlet,
} from "react-router-dom";
import { Leva } from "leva";
import { createRef, useState } from "react";
import { ThreeD } from "./components/ThreeD/ThreeD";
// @ts-ignore
import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition,
} from "react-transition-group";

import className from "classnames";

import "./App.scss";
import Home from "./routes/Home";
import { ROUTES } from ".";
import { AppContext } from "./context/AppContext";
import { State } from "./components/LineUp/LineUp";

export const PLAYER_KEYS = [
  "team-1-player-1",
  "team-1-player-2",
  "team-1-player-3",
  "team-1-player-4",
];

export type BrainStats = {
  attack: number;
  defense: number;
  strategy: number;
  speed: number;
};
export type CharacterConfig = {
  brain: { path: string; stats: BrainStats };

  formPath: string;
  selected: string;
  notSelected: string;
  idle: string;
};

export type CharacterByPlayerKey = {
  [key: string]: CharacterConfig;
};

export type Brain = {
  path: string;
  stats: { attack: number; defense: number; speed: number; strategy: number };
};
export const AVAILABLE_BRAINS: Brain[] = [
  {
    path: "/brains/brain_a.glb",
    stats: { attack: 22, defense: 8, speed: 60, strategy: 90 },
  },
  {
    path: "/brains/brain_b.glb",
    stats: { attack: 83, defense: 83, speed: 13, strategy: 43 },
  },
  {
    path: "/brains/brain_c.glb",
    stats: { attack: 34, defense: 42, speed: 40, strategy: 44 },
  },
  {
    path: "/brains/brain_d.glb",
    stats: { attack: 95, defense: 35, speed: 10, strategy: 50 },
  },
];
const MODEL_BY_PLAYER_KEY: CharacterByPlayerKey = {
  "team-1-player-1": {
    formPath: "/forms/charcter01.glb",
    brain: AVAILABLE_BRAINS[0],
    selected: "Celebrate",
    notSelected: "PowerUp",
    idle: "idle",
  },
  "team-1-player-2": {
    formPath: "/forms/charcter02.glb",
    brain: AVAILABLE_BRAINS[1],
    selected: "Celebrate",
    notSelected: "PowerUp",
    idle: "idle",
  },
  "team-1-player-3": {
    formPath: "/forms/charcter03.glb",
    brain: AVAILABLE_BRAINS[2],
    selected: "Celebrate",
    notSelected: "PowerUp",
    idle: "Idle",
  },
  "team-1-player-4": {
    formPath: "/forms/charcter04.glb",
    brain: AVAILABLE_BRAINS[3],
    selected: "Celebrate",
    notSelected: "PowerUp",
    idle: "Idle",
  },
};

export const Site = () => {
  const [currentScreen, setCurrentScreen] = useState<State>({
    screen: "players",
  });
  const [charactersByKey, setCharactersByKey] =
    useState<CharacterByPlayerKey>(MODEL_BY_PLAYER_KEY);
  const [resetCamera, setResetCamera] = useState<any>();

  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } =
    ROUTES.find((route) => route.path === location.pathname) ?? {};

  return (
    <div
      className={className({
        App: true,
        "App--is-dark": currentScreen.screen === "brainSelection",
      })}
    >
      <AppContext.Provider
        value={{
          currentScreen,
          setCurrentScreen,
          setResetCamera,
          resetCamera,
          charactersByKey,
          setCharactersByKey,
        }}
      >
        <Leva hidden={true} collapsed={true} />
        <div className="page-container">
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              // @ts-ignore
              nodeRef={nodeRef}
              timeout={500}
              classNames="page"
              unmountOnExit
            >
              {(state) => (
                // @ts-ignore
                <div ref={nodeRef} className="page">
                  {currentOutlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
          <ThreeD />
        </div>
      </AppContext.Provider>
    </div>
  );
};
