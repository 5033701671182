import { Suspense, useEffect, useRef } from "react";

import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  ACESFilmicToneMapping,
  Group,
  PCFSoftShadowMap,
  SpotLight,
  sRGBEncoding,
  Vector3,
} from "three";
import { Html } from "@react-three/drei";
import { useLocalStorage, useFetch } from "usehooks-ts";

import {
  Environment,
  PerspectiveCamera,
  Stats,
  GizmoHelper,
  GizmoViewport,
  Text,
} from "@react-three/drei";
import { CustomControls } from "../CustomControls/CustomControls";
import { useAppContext } from "../../context/AppContext";
import { Replay } from "../Replay/Replay";
import { folder, useControls } from "leva";
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette,
  SSAO,
} from "@react-three/postprocessing";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { useFetchNDJSON } from "../../hooks/useFetchNDJSON";

const players = [
  {
    name: "player 1",
    modelPath: "./forms/0afe41b8bf7af72acc87cc94ff155684.glb",
    team: "teamA",
  },
  {
    name: "player 2",
    modelPath: "./forms/0d05d97c0601dd01e3acba29545ae01b.glb",
    team: "teamA",
  },
  {
    name: "player 3",
    modelPath: "./forms/2ef00ff7c06b98c5d9d85ebc0c3f37e7.glb",
    team: "teamA",
  },
  {
    name: "player 4",
    modelPath: "./forms/10d4a43372e5c1001dc91bbe6ea0b081.glb",
    team: "teamA",
  },
  {
    name: "player 5",
    modelPath: "./forms/78cc0fff55a3ad1164c0662d62c510d3.glb",
    team: "teamB",
  },
  {
    name: "player 6",
    modelPath: "./forms/152b75c72213a0e951e429761250b274.glb",
    team: "teamB",
  },
  {
    name: "player 7",
    modelPath: "./forms/44776f6b5c2096981fff78d09f764c59.glb",
    team: "teamB",
  },
  {
    name: "player 8",
    modelPath: "./forms/72830d945c452bbd49fff8f16975d95f.glb",
    team: "teamB",
  },
];

const replayDataDict = {
  Animations: {
    dataURL: "./replays/JsonReplay - Animations.txt",
    players: [players[0]],
  },
  "Running in circles": {
    dataURL: "./replays/JsonReplay - Running in circles.txt",
    players: [players[0]],
  },
  "Ball Bounce Off Walls": {
    dataURL: "./replays/JsonReplay - Ball bounce off walls.txt",
    players: [players[0]],
  },
  "Super Shot Goals": {
    dataURL: "./replays/JsonReplay - Super Shot Goals.txt",
    players: [players[0]],
  },
  "Full Match": {
    dataURL: "./replays/full-match.txt",
    players,
  },
  "Walk Square": {
    dataURL: "./replays/walkSquare.txt",
    players,
  },
} as any;

const replayDataOptions = Object.keys(replayDataDict);

const startPosCamera = new Vector3(0, 5.151436771522862, 63.77854941000632);

type WorldProps = {};
const World = ({}: WorldProps) => {
  const { resetCamera, setResetCamera } = useAppContext();
  const routeGroups = useRef<Group>(null);
  // const [resetCam, setResetCam] = useState<boolean>(false);
  // const directionalLightRef = useRef<DirectionalLight>(null!);
  // useHelper(directionalLightRef, DirectionalLightHelper, 1, "red");

  useFrame(({ camera, clock, gl }) => {
    if (!routeGroups.current) return;
    const scale = Math.min(1, window.innerWidth / window.innerHeight);
    routeGroups.current.scale.set(scale, scale, scale);
  });

  const { scene } = useThree();

  useFrame(() => {});

  const onResetCamera = () => {
    setResetCamera(true);
  };
  const onResetCameraDone = () => {
    setResetCamera(false);
  };

  //  based on https://github.com/mrdoob/three.js/blob/master/examples/webgl_lights_spotlight.html
  const { gl } = useThree();
  gl.shadowMap.enabled = true;
  gl.shadowMap.type = PCFSoftShadowMap;

  gl.outputEncoding = sRGBEncoding;

  gl.toneMapping = ACESFilmicToneMapping;
  gl.toneMappingExposure = 1;

  const [{ showGrid }] = useControls(() => ({
    showGrid: { value: false },
  }));

  const [curReplayData, setCurReplayData] = useLocalStorage(
    "curReplayData",
    replayDataOptions[0]
  );
  useControls(() => ({
    replayData: {
      value: curReplayData,
      options: replayDataOptions,
      onChange: (option) => {
        setCurReplayData(option);
        if (option !== curReplayData) {
          window.location.reload();
        }
      },
    },
  }));

  const replayDataRef = useFetchNDJSON(replayDataDict[curReplayData].dataURL);

  return (
    <>
      {/* <Environment preset="city" /> */}
      <hemisphereLight args={[0xffffff, 0x444444, 0.45]} />
      <ambientLight intensity={0.21} />
      <fog args={[0xff0000, 3, 100]}></fog>

      <GizmoHelper
        alignment="bottom-right" // widget alignment within scene
        margin={[80, 80]} // widget margins (X, Y)
      >
        <GizmoViewport
          axisColors={["red", "green", "blue"]}
          labelColor="black"
        />
      </GizmoHelper>

      {showGrid && <gridHelper args={[100, 100]} />}

      <CustomControls
        resetPosition={resetCamera}
        onResetDone={onResetCameraDone}
      />
      <PerspectiveCamera
        makeDefault
        fov={40}
        far={1000}
        position={startPosCamera}
      />

      <group receiveShadow ref={routeGroups}>
        <group position={[0, 0, 0]}>
          <Replay
            // stadium="/stadium/Zombie_Arena.glb"
            stadium="/stadium/Family_Zombie.glb"
            replayData={replayDataRef.current}
            players={replayDataDict[curReplayData].players}
          />
        </group>
      </group>
    </>
  );
};
type ModelProps = {};

export const ThreeD = () => {
  const [
    { activateFilters, intensity, luminanceThreshold, luminanceSmoothing },
    set,
  ] = useControls(() => ({
    filters: folder({
      activateFilters: false,
      intensity: { value: 1.68, min: 0, max: 10, step: 0.001 }, // The bloom intensity.
      // width:{Resizer.AUTO_SIZE} ,// render width
      // height:{Resizer.AUTO_SIZE} ,// render height
      // kernelSize:{KernelSize.LARGE}, // blur kernel size
      luminanceThreshold: { value: 0.96, min: 0, max: 1, step: 0.001 }, // luminance threshold. Raise this value to mask out darker elements in the scene.
      luminanceSmoothing: { value: 0.025, min: 0, max: 1, step: 0.001 },
    }),
  }));
  return (
    // <Canvas camera={{ fov: 50 }} dpr={[1, 2]}>
    <>
      <Canvas shadows dpr={[1, 2]}>
        <Suspense fallback={null}>
          <World />
        </Suspense>
        <Stats />
        {/* https://docs.pmnd.rs/react-postprocessing/effects/custom-effects */}
        {activateFilters && (
          <EffectComposer>
            <Bloom
              intensity={intensity}
              luminanceThreshold={luminanceThreshold}
              luminanceSmoothing={0.025}
            />
            <SSAO />
          </EffectComposer>
        )}
      </Canvas>
    </>
  );
};
