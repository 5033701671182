/* eslint-disable react/no-unknown-property */
import { useCallback, useEffect, useRef, useState } from "react";
import { Vector3 } from "three";
import { useControls, folder } from "leva";

import { Html, useAnimations, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { AnimationTrigger, RecordedEvent, parseEvent } from "./RecordedEvent";
import { generateDomeImpactFx } from "./replayFX";
import { Player } from "./Player";
import { Ball } from "./Ball";
import { DomeHit } from "./DomeHit";
import { generateUUID } from "three/src/math/MathUtils";
import { Stadium } from "./Stadium";
import { ShowGoalPopUp } from "./ShowGoalPopUp";
import { useScoreboardReducer } from "./scoreboardReducer";
import { MatchDescription } from "./MatchDescription";

type PlayerState = {
  position: Vector3;
  rotation: number;
  animationTrigger?: AnimationTrigger;
};
type BallState = {
  ownerIndex?: number;
  position: Vector3;
  isSuperShot: boolean;
};
type DomeHitProp = { normal: Vector3; position: Vector3; uid: string };
export type ScoreType = {
  teamA: number;
  teamB: number;
  teamScored: "teamA" | "teamB" | null;
};
export type GameState = {
  currentFrame: number;
  players: PlayerState[];
  ball: BallState;
  timeScale: number;
  pastEvents: { players: { frame: number; eventType?: AnimationTrigger }[] };
  score: ScoreType;
};

export type RecordedFrames = [
  {
    events: RecordedEvent[];
  }
];

type ModelProps = {
  stadiumPath: string;
  replayData: RecordedFrames;
  players: any[];
};
const FPS = 60;
const initialState: GameState = {
  currentFrame: 0,
  timeScale: 1,
  players: [
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
  ],
  pastEvents: {
    players: [
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
    ],
  },
  ball: { position: new Vector3(), isSuperShot: false },
  score: { teamA: 0, teamB: 0, teamScored: null },
};

export const ReplayModel = ({
  stadiumPath,
  replayData,
  players,
}: ModelProps) => {
  const gameState = useRef<GameState>(initialState);
  const [domeImpacts, setDomeImpacts] = useState<DomeHitProp[]>([]);
  const [showGoal, setShowGoal] = useState<boolean>(false);
  const [
    {
      totalPassesTeamA,
      totalPassesTeamB,
      totalTacklesTeamA,
      totalTacklesTeamB,
      scoreA,
      scoreB,
      commentary,
    },
    scoreboardDispatch,
  ] = useScoreboardReducer();

  // we just want a global mixer to scale the time with
  const { mixer } = useAnimations([]);
  const [
    { gameSpeed, paused, frame, showState, showStadium, stadiumScale },
    set,
  ] = useControls(() => ({
    gameSpeed: {
      value: 1,
      min: 0,
      max: 10,
      step: 0.1,
    },
    showState: { value: false },
    Stadium: folder({
      showStadium: { value: true },
      stadiumScale: {
        // 3.6166 is from unity but seems off when loading the test stadium
        // value: 3.6166,
        value: 4,
        min: 1,
        max: 6,
        step: 0.1,
      },
    }),

    paused: { value: false },
    frame: {
      value: 0,
      min: 0,
      max: replayData.length - 1,
      step: 1,
      // render: (get) => get("paused"),
    },
  }));

  useEffect(() => {}, [gameState.current.score]);

  useFrame(() => {
    const maxFrame = replayData.length - 1;

    if (paused) {
      mixer.timeScale = 0;
      gameState.current.currentFrame = Math.min(maxFrame, frame);
    } else {
      mixer.timeScale = gameSpeed;
      const targetFrame = Math.min(maxFrame, Math.floor(mixer.time * FPS));
      set({ frame: targetFrame });
      gameState.current.currentFrame = targetFrame;
    }
    gameState.current.timeScale = mixer.timeScale;
    processFrameEvents(replayData[gameState.current.currentFrame].events);
  });

  useEffect(() => {
    if (!scoreA && !scoreA) {
      return;
    }
    setShowGoal(true);
  }, [scoreA, scoreB]);
  const onShowGoalAnimationComplete = () => {
    setShowGoal(false);
  };
  const processFrameEvents = (recordedFrameEvents: RecordedEvent[]) => {
    // TODO:  Main down side with this is its processing the recordedFrameEvents 3 times.
    //        Ideally update to process all events once to update objects.
    const ballFromState = gameState.current.ball;
    recordedFrameEvents.forEach((e) => {
      const frameEvent = parseEvent(e);
      frameEvent.handler(gameState.current, scoreboardDispatch);

      // When ball is in the field, correct the ball position to the front of the agent
      if (ballFromState.ownerIndex !== undefined) {
        const player = gameState.current.players[ballFromState.ownerIndex];
        const rotationInRad = player.rotation * (Math.PI / 180);

        ballFromState.position.set(
          player.position.x + Math.sin(rotationInRad),
          player.position.y,
          player.position.z - Math.cos(rotationInRad)
        );
      }

      // TODO: put this logic in class RecordedEvent_DomeImpact?
      if (e.position && e.normal) {
        setDomeImpacts([
          ...domeImpacts,
          {
            uid: generateUUID(),
            normal: new Vector3(e.normal.x, e.normal.y, -e.normal.z),
            position: new Vector3(e.position.x, e.position.y, -e.position.z),
          },
        ]);

        generateDomeImpactFx(e.position, e.normal);
      }
    });
  };

  // we don't want this updated every render!!!
  const removeImpact = useCallback((orginalUID: string) => {
    setDomeImpacts((current) =>
      current.filter(({ uid }) => uid !== orginalUID)
    );
  }, []);

  return (
    <>
      {showGoal && (
        <Html center>
          <ShowGoalPopUp
            onAnimationComplete={onShowGoalAnimationComplete}
            score={gameState.current.score}
          />
        </Html>
      )}
      <Html wrapperClass="game-ui-wrapper">
        <div className="game-ui-container">
          <div className="game-ui" aria-hidden={true}>
            <div className="game-ui__team">
              <h3>Lil' Puckers </h3>
              <div className="game-ui__team-stat">
                <div className="game-ui__team-stat-name">Goals</div>
                <div
                  className="game-ui__team-stat-score"
                  // aria-live="assertive"
                  // aria-atomic="true"
                  // aria-label="Lil' Puckers score"
                >
                  {scoreA}
                </div>
              </div>
              <div className="game-ui__team-stat">
                <div className="game-ui__team-stat-name">Total tackles</div>
                <div className="game-ui__team-stat-score">
                  {totalTacklesTeamA}
                </div>
              </div>
              <div className="game-ui__team-stat">
                <div className="game-ui__team-stat-name">Total passes</div>
                <div className="game-ui__team-stat-score">
                  {totalPassesTeamA}
                </div>
              </div>
            </div>
            <div className="game-ui__team">
              <h3>FC Cringe</h3>
              <div className="game-ui__team-stat game-ui__team-stat--alt">
                <div className="game-ui__team-stat-name">Goals</div>
                <div
                  className="game-ui__team-stat-score"
                  // aria-live="assertive"
                  // aria-atomic="true"
                  // aria-label="FC Cringe score"
                >
                  {scoreB}
                </div>
              </div>
              <div className="game-ui__team-stat game-ui__team-stat--alt">
                <div className="game-ui__team-stat-name">Total tackles</div>
                <div className="game-ui__team-stat-score">
                  {totalTacklesTeamB}
                </div>
              </div>
              <div className="game-ui__team-stat game-ui__team-stat--alt">
                <div className="game-ui__team-stat-name">Total passes</div>
                <div className="game-ui__team-stat-score">
                  {totalPassesTeamB}
                </div>
              </div>
            </div>
          </div>
          <div className="game-ui__commentary">
            <MatchDescription replayData={replayData} />
            {/* <ul>
              {commentary.map((c, index) => {
                const liveAtr =
                  c.forceVoiceOver || commentary.length === 1
                    ? "assertive"
                    : "polite";
                const atomicAtr =
                  c.forceVoiceOver || commentary.length === 1
                    ? "false"
                    : "false";
                return (
                  <li key={index}>
                    <span aria-live={liveAtr} aria-atomic={atomicAtr}>
                      {c.comment}
                    </span>
                  </li>
                );
              })}
            </ul> */}
          </div>
        </div>
      </Html>
      {showState && (
        <>
          <Html>
            <span className="warning">
              WARNING: when stepping frames, this isn't auto-updated! toggle the
              debug panel to force a re-render
            </span>
            <div className="game-states">
              <pre className="game-state">
                <h1>
                  gamestate @frame {gameState.current.currentFrame} || {frame}
                </h1>
                <code>{JSON.stringify(gameState.current, null, 2)}</code>
              </pre>
              <pre className="game-state game-state--original">
                <h1>
                  replayJSON @frame {gameState.current.currentFrame} || {frame}
                </h1>
                <code>
                  {JSON.stringify(
                    replayData[gameState.current.currentFrame].events,
                    null,
                    2
                  )}
                </code>
              </pre>
            </div>
          </Html>
        </>
      )}
      {/* Stadium */}
      {showStadium && (
        <group scale={stadiumScale}>
          <Stadium stadiumPath={stadiumPath} gameState={gameState} />
        </group>
      )}
      {players.map(({ modelPath }, index) => {
        return (
          <Player
            key={index}
            playerIndex={index}
            gameState={gameState}
            path={modelPath}
          />
        );
      })}
      <Ball gameState={gameState} />
      {domeImpacts.map((impact) => {
        return (
          <DomeHit
            key={impact.uid}
            uid={impact.uid}
            onComplete={removeImpact}
            position={impact.position}
            normal={impact.normal}
          />
        );
      })}
    </>
  );
};
